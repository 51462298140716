import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";

const CtaBanner = ({ headline, btnTxt, btnLink, bgColor, classes }) => {
  const ctaLink = btnLink || "/contact-us";

  return (
    <section className={`bg-${bgColor} py-3 py-md-4 py-xl-5 ${classes}`}>
      <Container>
        <Row className="align-items-md-center">
          <Col
            xs={12}
            md={7}
            lg={9}
            className="mb-4 mb-md-0 text-center text-md-left align-items-xl-center"
          >
            <p
              className="text-center text-md-left text-primary d-inline"
              style={txt}
            >
              {headline}
            </p>
          </Col>
          <Col xs={12} md={5} lg={3}>
            <Button
              as={Link}
              variant="secondary"
              className="float-md-right w-100 cta-btn-gtm"
              to={ctaLink}
              style={btn}
              id="cta-banner-button"
            >
              {btnTxt}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const btn = {
  fontSize: "1.5rem",
};

const txt = {
  fontSize: "1.75rem",
};

export default CtaBanner;
