import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CtaBanner from "../components/cta-banner";
import HorizontalTestimonial from "../components/horizontal-testimonial";
import Hero from "../components/hero";

const AboutPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "17.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				russ: file(relativePath: { eq: "testimonials/Mike-Sherwood.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				clare: file(
					relativePath: { eq: "testimonials/antoinette-chappell.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "About Jason Cornes";
	const description =
		"I’ve helped hundreds of business owners, leaders and executives think differently, do things differently, and be more productive and happy in their work.";
	const russ = data.russ.childImageSharp.gatsbyImageData;
	const clare = data.clare.childImageSharp.gatsbyImageData;
	const heroImage = data.heroImg.childImageSharp.fluid;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "About",
				item: {
					url: `${siteUrl}/about`,
					id: `${siteUrl}/about`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "About Jason Cornes Business Coach",
						},
					],
				}}
			/>
			<Hero
				title="About Jason Cornes"
				backgroundImage={heroImage}
				isDarken={true}
			/>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Business Mentoring and Executive Coaching
							</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>Let’s face it - business is full of challenges.</p>
							<p>
								There’s so much to contend with - from sales to marketing,
								customer service, maintaining profitability, scaling and growth,
								huge levels of stress, whacks to your self-confidence, risk, and
								everything else in between.
							</p>
							<p>
								With so many plates spinning at once, alongside of course doing
								the job you’re paid to do, is it any wonder that sometimes those
								plates come crashing down?
							</p>
							<p>
								I believe you deserve better, which is where my business
								mentoring and executive coaching comes into play - supporting
								you before everything gets too much.
							</p>
							<p>
								I’ve helped hundreds of entrepreneurs, leaders and executives in
								the UK, USA and across the world think differently, do things
								differently, and be more productive and happier in their work.
							</p>
							<p>
								By combining my business qualifications (DMS & MBA), 5 years of
								counselling and psychotherapy training (Person Centred and
								Transactional Analysis) and getting on for four decades in the
								business world, I can work with you to align your small
								business, career and personal goals to help you get to where you
								want to be.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 bg-tertiary text-white">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">My background</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<ul className="pl-4">
								<li>
									35+ years in business, in fact - I’ve never had a job. I’ve
									been self-employed and owned businesses since my teens, so
									I’ve done it all, made plenty of mistakes so you don’t have to
									and learned a lot. I’ll share my many successes with you too.
								</li>
								<br />
								<li>
									I can see and have experienced both sides of the
									entrepreneurial world, from being self-employed in the trades
									services, to offering professional services to individuals and
									businesses.
								</li>
								<br />
								<li>
									Five year’s training in counselling and psychotherapy taught
									me how to listen! I’ll help you understand your personality
									and why you run your business in the way that you do, how you
									see the world, and provide insights into how we can maximize
									your strengths and create a vision that will give you the life
									you want.
								</li>
								<br />
								<li>
									I’ve been busy delivering bespoke mentoring and coaching since
									2017. I currently work with about 30 different clients at any
									one time from a broad range of small businesses or
									occupations.
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<HorizontalTestimonial
						name="Mike Sherwood. FreshOnline Ltd"
						review="I have been working with Jason Cornes for the last 5 months and have seen amazing results. Jason has been able to understand my business and identify opportunities to improve what we already do and find new ways to grow and expand. Since working with Jason we have employed another full time staff member, added new services and increased profitability on our existing work. I feel so much more in control and confident about the future. I have no hesitation recommending Jason and I look forward to continuing to use his business coaching services long term."
						profilePic={russ}
						linkedIn="https://www.linkedin.com/in/mikesherwood147"
					/>

					<HorizontalTestimonial
						name="Antoinette Chappell. Managing Director. ARC Writing and Translation Services."
						review="I have been working with Jason for six months and in that time I have learnt so much and developed a lot as a person and business owner. What do I like best about working with Jason? His approach to business mentoring, which is both friendly and professional and full of valuable insights into how to successfully run and grow a business. Jason has finely-tuned listening skills and an ability to input information, process it and then output a suggestion that I would never have considered. This means he can drop a career-changing bombshell that ignites a spark that leads to a revolutionary new idea that will transform your life and business forever. He challenges my perception of what I can and can’t achieve, stretching the boundaries of what I thought was possible to the point that the sky is literally the limit! Every time I meet him I come away with my mind buzzing with new exciting ideas about how to improve my business in ways that seem obvious afterwards, but would never have occurred to me on my own. I value his contribution to my business very highly and would happily recommend his services to other businesses that need a fresh approach and guidance from someone with extensive experience of a wide variety of businesses from different sectors."
						profilePic={clare}
						linkedIn="https://www.linkedin.com/in/antoinette-chappell-b0a83812a/"
					/>
				</Container>
			</section>
			<section className="pb-md-5">
				<Container>
					<Row>
						<Col>
							<h2>So how can I help you?</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>My clients come to me because...</p>
							<p>
								They’re totally committed to action, success and growth, in
								which case, I show them how to get there quicker, with less
								stress and fewer risks.
							</p>
							<p>
								Or they find themselves in need of guidance regarding their next
								steps - in which case, I help them take positive, decisive
								action to take their businesses to the next level.
							</p>
							<p>
								Let’s get the ball rolling, let me know which category you fall
								in by scheduling a discovery call below.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="Book a call with Jason"
				btnTxt="Get in Touch"
				btnLink="/contact-us"
				bgColor="light"
				classes="d-none d-md-block"
			/>
			<CtaBanner
				btnTxt="Book a call with Jason"
				btnLink="/contact-us"
				bgColor="white"
				classes="d-md-none mb-3"
			/>
		</Layout>
	);
};
export default AboutPage;
